import React from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

const CTA = styled.img`
  width: 250px;
  animation: pulse 1s infinite;

  @keyframes pulse {
    0% {
      transform: scaleX(1);
    }
    50% {
      transform: scale3d(1.05, 1.05, 1.05);
    }
    to {
      transform: scaleX(1);
    }
  }
`;
interface ModalProps {
  token: string | null;
}

const Modal: React.FC<ModalProps> = ({ token }) => {
  return (
    <ModalOverlay>
      <ModalContent>
        <h1>
          Você ganhou até <span> 60 rodadas grátis + 100% de bônus +</span>{" "}
          chance de ser sorteado a um{" "}
          <span>iPhone 15. Agora, escolha as suas rodadas grátis </span>
        </h1>
        <div>
          <a
            href={`https://go.aff.7k-partners.com/vfihopkm?utm_campaign=kwairaspadinha`}
          >
            <div>
              <span>10 rodadas grátis</span> | Deposite R$20
            </div>
          </a>
          <a
            href={`https://go.aff.7k-partners.com/vfihopkm?utm_campaign=kwairaspadinha`}
          >
            <div>
              <span>30 rodadas grátis</span> | Deposite R$50
            </div>
          </a>
          <a
            href={`https://go.aff.7k-partners.com/vfihopkm?utm_campaign=kwairaspadinha`}
          >
            <div>
              <span>60 rodadas grátis</span> | Deposite R$100
            </div>
          </a>
        </div>
        <div>
          <img src="https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/27cbf316-d4fb-4ea6-3ad0-f0b6fcf0bf00/public" />
        </div>
        <a
          href={`https://go.aff.7k-partners.com/vfihopkm?utm_campaign=kwairaspadinha`}
        >
          <CTA src="https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/6cc4bf98-0995-449b-8583-5af493827900/public" />
        </a>
      </ModalContent>
    </ModalOverlay>
  );
};

export default Modal;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5); // Dark background with 50% opacity

  z-index: 1000;
`;

const ModalContent = styled.div`
  padding: 40px 20px;
  border-radius: 5px;
  width: 80%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-top: 105px;
  gap: 25px;
  text-align: center;
  position: relative;
  background-image: url(https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/81ad1bc2-01d5-4033-b35c-c3b59b4af200/public);
  background-size: 100% 100%;

  h1 {
    font-family: "Gotham-Black";
    color: white;
    font-size: 18px;
  }

  a {
    text-decoration: none;
    color: white;
    font-family: "Gotham-Thin";

    div {
      padding: 10px 0px;
      border: 1px solid #cdc5c5;
      margin: 15px 0px;
      border-radius: 8px;
    }
  }

  div {
    button {
      background: none;
      border: none;
    }
  }

  span {
    font-family: "Gotham-Black";
  }

  @media screen and (min-width: 768px) {
    max-width: 450px;
  }

  h2 {
    color: white;
    font-family: "Gotham-Black";
    font-size: 22px;
  }

  p {
    color: #7d7d84;
    font-family: "Gotham-Medium";
  }
`;

const CloseButton = styled.span`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
  color: #7d7d84;
`;
